<template>
  <div>
    <general-table
      :api-url="APIURL"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="true"
      :block-content="false"
      :edit-content="true"
      :view-content="false"
      :add-type="addType"
      :selectable="false"
      :columns="columns"
      :type="type"
      :per-page="perPage"
    />
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: `certificate/${this.$route.params.MCR_id}/${this.$route.params.CM_id}/components/${this.$route.params.SE_id}/section`,
      addType: 'Add Sections',
      addComponentName: 'add-sections',
      editComponent: 'edit-sections',
      viewContent: true,
      type: 'page',
      perPage: 50,
      columns: [
        { key: 'id', sortable: false },
        { key: 'name.ar', label: 'Section Name AR ' },
        { key: 'name.en', label: 'Section Name EN' },
        { key: 'requirements', label: 'Requirements' },
        { key: 'actions' },
      ],
    }
  },
  methods: {

  },
}
</script>

    <style>

    </style>
